import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import '../PageCss/HansaFlexIndiaPages/Journey.css';
import JourneyCard from '../../Components/JourneyCard/JourneyCard';
import img2018 from '../../assets/Journey/2018.jpg';
import img2020 from '../../assets/Journey/2020.jpg';
import img2021 from '../../assets/Journey/2021.jpg';
import img2022 from '../../assets/Journey/2022.jpg';
import img2023 from '../../assets/Journey/2023 (2).jpg';
import JourneyCardR from '../../Components/JourneyCard/JourneyCardR';
import CenterStage from '../../Components/CenterStage/CenterStage';
import imgHistoryStage from '../../assets/company-history.png';
import AOS from 'aos';
import 'aos/dist/aos.css';
import UAE from "../../assets/Journey/UAE.jpg"

function Journey() {

    const isMobile = window.innerWidth <= 789;

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
        AOS.init({
            // Global settings:
            disable: false,
            startEvent: 'DOMContentLoaded',
            initClassName: 'aos-init',
            animatedClassName: 'aos-animate',
            useClassNames: false,

            // Settings that can be overridden on per-element basis:
            offset: 300,
            delay: 0,
            duration: 500,
            easing: 'ease',
            once: false,
            mirror: false,
            anchorPlacement: 'top-bottom',
        });
    }, []);



    return (
        <div className='journey-content'>
            <CenterStage imageSrc={UAE} title="Come with us on our Journey !" paragraph="HANSA-FLEX MIDDLE EAST has a rich history marked by strategic evolution and adaptability. Navigating through time, it has demonstrated resilience and innovation, continually meeting challenges and expanding its influence in the industry." />

            <div className='div-timeline-wrapper'>
                <br /><br /><br /><br />
                {isMobile ? <></> : <><div className='vertical-line'></div></>}

                <div>
                    <JourneyCard
                        title="2018"
                        description="HANSA-FLEX India Private Limited was inaugurated and incorporated in Pune, Maharashtra"
                        imageUrl={img2018}
                        additionalDescription="INP innaguration"
                    />
                </div>

                <div >
                    <JourneyCardR
                        title="2020"
                        description="HANSSA-FLEX India celebrated the second inauguration of its INP plant."
                        imageUrl={img2020}
                        additionalDescription="INP Second Anniversary"
                    />
                </div>

                <div >
                    <JourneyCard
                        title="2021"
                        description="2021 the idea of establishing a HANSA-FLEX manufacturing facility in India was conceived. "
                        imageUrl={img2021}
                        additionalDescription="IFP ideation"
                    />
                </div>

                <div>
                    <JourneyCardR
                        title="2022"
                        description="HANSA-FLEX (IFP) India private limited was inaugurated and incorporated in Pune, Maharashtra, the first inserts and ferrules manufacturing plants"
                        imageUrl={img2022}
                        additionalDescription="IFP innaguration"
                    />
                </div>

                <div>
                    <JourneyCard
                        title="2023"
                        description=" due to increase in customer demand a new serial hose production unit has joined HF India family. "
                        imageUrl={img2023}
                        additionalDescription="SHP innaguration"
                    />
                </div>
            </div>
        </div>
    );
}

export default Journey;
