import React, { useEffect } from 'react';
import ContactUsCard from '../../Components/ContactUsCard/ContactUsCard';
import CenterStage from '../../Components/CenterStage/CenterStage';
import imgContactusAfrican from '../../assets/Contact us-African.png';
import imgHeadquarters from "../../assets/ContactLocation/Hansa-Flex-headquarters.png"
import imgSAJohannesburg from "../../assets/ContactLocation/SAJohannesburg.png"
import imgSADurban from "../../assets/ContactLocation/SADurban.png"
// import imgUAEDubai from "../../assets/ContactLocation/UAEDubainew.png"
import imgUAEDubai from "../../assets/ContactLocation/Screenshot1.png"
// import imgUAEAbudhabi from "../../assets/ContactLocation/UAEAbudhabinew.png"
import imgUAEAbudhabi from "../../assets/ContactLocation/Screenshot2.png"
import imgUAEAjman from "../../assets/ContactLocation/Screenshot3.png"
// import imgUAEAjman from "../../assets/ContactLocation/UAEAjmannew.png"
import imgUAEContactus from "../../assets/ContactLocation/UAEContactus.png"
import imgUAE from "../../assets/ContactLocation/UAE.jpg"
// import imgEntzheimLocation from '../../assets/FranceUnits/Entzheim/ENTZHEIN_Location.png'
// import imgGemenosLocation from '../../assets/FranceUnits/Gemenos/GEMENOS_Location.png'
// import imgVitrollesLocation from '../../assets/FranceUnits/Vitrolles/VITROLLES_Location.png'



const ContactUs = () => {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const units = [
        {
            name: 'HANSA-FLEX HYDRAULICS MIDDLE EAST, Dubai ',
            location: 'Dubai Industrial Park DWC Warehouse Unit 25B',
            telephone: '+971 (0) 4-242-4908',
            // fax: "jhgyuyuii",
            mail: 'uac@hansa-flex.com',
            image: imgUAEDubai
        },


        {
            name: 'HANSA-FLEX HYDRAULICS MIDDLE EAST, Abu Dhabi',
            // description: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
            location: 'Mussafah Industrial Area, Abu Dhabi.',
            telephone: ' +971 (0) 2-554-8866, ',
            mail: 'uaa@hansa-flex.com',
            image: imgUAEAbudhabi
        },

        {
            name: 'HANSA FLEX HYDRAULICS MIDDLE EAST MECHANICAL EQUIPMENT TRADING L.L.C.  ',
            // description: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
            location: 'Jurf Industrial Area, Ajman.',
            telephone: '+971 (0) 6-546-9836',
            mail: 'uac@hansa-flex.com',
            image: imgUAEAjman
        },


    ];

    return (
        <div className="">
            <div>
                <CenterStage
                    imageSrc={imgUAE}
                    title={"Get in touch with HANSA-FLEX MIDDLE EAST "}
                    paragraph={"Dive into a world of hydraulic expertise and personalised service. Contact us today and see how HANSA-FLEX Middle East can take your hydraulic operations to new heights."} />
            </div>
            <div className=''>
                <ContactUsCard units={units} />
            </div>
        </div>
    );
};

export default ContactUs;
