import React, { useEffect } from 'react';
import CertificateCard from '../../Components/CertificateCard/CertificateCard';
// import imgAbsCert1 from '../../assets/Certificates/ABS-Final Certificate WO 5477145-1.jpg';
// import imgAOM from '../../assets/Certificates/Approved AOM-AMT00000SF 1-1.jpg';
// import imgEn15085 from '../../assets/Certificates/Certificate-EN15085_ENG_HANSA_FLEX_INDIA_signed-1.jpg';
// import imgISO3834 from '../../assets/Certificates/Certificate-ISO3834_ENG_HANSA_FLEX_INDIA_signed_SvR-1.jpg';
// import imgBOM2200556 from '../../assets/Certificates/Inspection Certificate BOM2200556-SHI-01.jpg';
import ICV from '../../assets/Certificates/ICV.png';
import imgISO9001 from '../../assets/Certificates/ISO-9001-2015-New Certificate-2022-2025 1.jpg';
// import imgTFW from '../../assets/Certificates/TFW_LR_Type_Approval_TFW_LR2025131TA_CERT 1.jpg';
// import imgTUV1 from '../../assets/Certificates/TUV Certificate -ISO14001 1.jpg';
// import imgTUV2 from '../../assets/Certificates/TUV Certificate -ISO45001 1.jpg';


function Certificates() {


    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    return (
        <div>
            <CertificateCard title={"IN-COUNTRY VALUE CERTIFICATE "} description={"The In-Country Value Certificate issued by HansaFlex UAE acknowledges the company's commitment to maximizing local value creation and contribution to the United Arab Emirates' economy"} imageUrl={ICV} />
            <CertificateCard title={"ISO-9001-2015-New Certificate-2022-2025 1"} description={"Accreditation for ISO 9001:2015 standards, affirming commitment to quality management systems, enhancing organizational efficiency, customer satisfaction, and global market competitiveness."} imageUrl={imgISO9001} />
            {/* <CertificateCard title={"ABS-Final Certificate WO 5477145-1 "} description={"Official documentation confirming successful completion of Work Order 5477145-1, demonstrating adherence to ABS standards, ensuring quality, safety, and regulatory compliance in the specified project or task."} imageUrl={imgAbsCert1} /> */}
            {/* <CertificateCard title={"Approved AOM-AMT00000SF 1-1"} description={"Certification recognizing adherence to quality standards for specified processes, ensuring reliability and compliance within the industry, fostering trust and credibility."} imageUrl={imgAOM} />
            <CertificateCard title={"Certificate-EN15085_ENG_HANSA_FLEX_INDIA_signed-1 "} description={"Certificate-EN15085_ENG_HANSA_FLEX_INDIA: Recognizing compliance with EN 15085 standards, ensuring proficiency in welding of railway vehicles, upholding safety and quality benchmarks in rail transport systems."} imageUrl={imgEn15085} />
            <CertificateCard title={"Certificate-ISO3834_ENG_HANSA_FLEX_INDIA_signed_SvR-1 "} description={"Certificate-ISO3834_ENG_HANSA_FLEX_INDIA: Acknowledgement of ISO 3834 compliance, demonstrating excellence in welding quality management, ensuring consistent adherence to industry standards for superior welded products."} imageUrl={imgISO3834} />
            <CertificateCard title={"Inspection Certificate BOM2200556-SHI-01 "} description={"Inspection Certificate BOM2200556-SHI: Verification of inspection procedures and standards compliance, guaranteeing product integrity and reliability, enhancing customer confidence in quality assurance and regulatory adherence."} imageUrl={imgBOM2200556} />
            // <CertificateCard title={"ISO-9001-2015-New Certificate-2022-2025 1"} description={"Accreditation for ISO 9001:2015 standards, affirming commitment to quality management systems, enhancing organizational efficiency, customer satisfaction, and global market competitiveness."} imageUrl={imgISO9001} />
            <CertificateCard title={"TFW_LR_Type_Approval_TFW_LR2025131TA_CERT 1"} description={"Type Approval Certification from LR, signifying compliance with technical and safety requirements, ensuring reliability and adherence to stringent marine industry standards."} imageUrl={imgTFW} />
            <CertificateCard title={"TUV Certificate -ISO14001 1"} description={"Recognition for ISO 14001 compliance, demonstrating commitment to environmental management, sustainability, and eco-friendly practices, contributing to a greener and more sustainable future."} imageUrl={imgTUV1} />
            <CertificateCard title={"TUV Certificate -ISO45001 1"} description={"TUV Certificate -ISO45001: Certification attesting to ISO 45001 compliance, ensuring effective occupational health and safety management systems, prioritizing employee welfare, and mitigating workplace risks for a safer work environment."} imageUrl={imgTUV2} /> */}

        </div>
    );
}

export default Certificates;